
import React, { Component } from 'react';

import { connect } from 'react-redux';
import Logo from '../../assets/images/Logo.svg'

import Moment from 'react-moment';


class GeneratedReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderList: null,
            itemList: null,
            restaurent_id: null,
            dashboardData: {},
            errors: {},
            serverError: {},
            isLoading: false,
            activeTab: 1,
            book_url: "",
            epub: "",
            original_book_name: "",
            reportContent: [],
            data: {},
            totalEarning: 0,
            totalPayment: 0,
            paymentList: [],
            totalAuthorAmountCleared: 0,
            totalAuthorBalance: 0,
            totalAuthorEarning: 0,
            totalTLBCEarning: 0,
            totalSale: 0


        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }


    componentWillMount() {
        if (this.props?.location?.state?.content) {


            console.log(this.props.location.state.content)
            this.setState({
                reportContent: this.props.location.state.content

            })
        }

        if (this.props?.location?.state?.data) {


            //console.log(this.props.location.state.data)
            this.setState({
                data: this.props.location.state.data

            })
        }

    }
    componentDidMount() {
        console.log(this.props.user)
        this.getTotal()


    }



    printReceipt() {
        window.print();
    }

    getTotal = () => {
        let totalAuthorEarning = 0
        let totalAuthorAmountCleared = 0
        let totalTLBCEarning = 0
        let totalAuthorBalance = 0
        let totalSale = 0
        var totalPayment = 0
        var list = [];
        var totalinPKR = 0
        var totalinUSD = 0
        var totalSaleinPKR = 0
        var totalSaleinUSD = 0


        console.log('PaymentList', this.state.reportContent)
        for (let i = 0; i < this.state.reportContent.length; i++) {
            let order = this.state.reportContent[i]
            let order_has_books = order.order_has_books;
            let currency = order.currency;
            let dollarRate = order.dollarRate;


            for (let j = 0; j < order_has_books.length; j++) {
                const order_book = order_has_books[j]
                const bookSellingPrice = currency === 'PKR' ?  Number(order_book.bookAmount) : order_book.bookAmount * dollarRate 
                totalAuthorEarning += bookSellingPrice * 0.7
                totalTLBCEarning += bookSellingPrice * 0.3
                totalAuthorAmountCleared +=   Number(order_book.clearingAmount) ?? 0
                totalAuthorBalance +=  (bookSellingPrice * 0.7) - (order_book.clearingAmount ?? 0)
                totalSale += bookSellingPrice
            }
        }




        this.setState({
            totalAuthorEarning: totalAuthorEarning.toFixed(2),
            totalTLBCEarning: totalTLBCEarning.toFixed(2),
            totalAuthorAmountCleared: totalAuthorAmountCleared.toFixed?.(2),
            totalAuthorBalance: totalAuthorBalance.toFixed(2),
            totalSale: totalSale.toFixed(2)
        })
    }

    renderTableRows = () => {

        return this.state.reportContent.map((item, i) =>
            <>
                {item?.order_has_books?.length > 0 && item?.order_has_books.map((citem, index) =>
                    <tr>
 
                        <td><Moment format="DD-MM-YY">{item.createdAt}</Moment></td>
                        <td>{item?.Order_ID}</td>

                        <td>{citem.book?.Name}</td>
                        <td> {item?.currency !== "PKR" ?
                   
                         (<>     
                              {(citem.bookAmount * item?.dollarRate)?.toFixed(2)}
                           <span style={{ color: '#adb5bd',paddingLeft:'5px' }}>
                         {
                           `(${`$` + citem.bookAmount} * ${item.dollarRate + ' CR'})`
                         }
                       </span>   
                       
                       </>) : citem.bookAmount}
                        
                        </td>
                        <td>{item?.Payment_Method}</td>
                        <td>

                            {
                                item.currency === 'PKR' ? (
                                    (citem.bookAmount * 0.7)?.toFixed(2) + ' PKR'
                                ) : (
                                    <>   
                                  {  (citem.bookAmount * item?.dollarRate * 0.7)?.toFixed(2) + ' PKR' }
                            
                                    </>
                                )
                            }
                        </td>

                        <td>

                            {
                                item.currency === 'PKR' ? (
                                    (citem.bookAmount * 0.3)?.toFixed(2) + ' PKR'
                                ) : (
                                    (citem.bookAmount * item?.dollarRate * 0.3)?.toFixed(2) + ' PKR'
                                )
                            }
                        </td>
                        <td>
                            {
                                item.currency == 'PKR' ? (
                                    citem.bookAmount + ' PKR'
                                ) : (

                                    (citem.bookAmount * item?.dollarRate)?.toFixed(2) + ' PKR'
                                )
                            }
                        </td>

                    </tr>
                )}
            </>
        )



    }

    renderPayement = () => {
        return this.state.paymentList.filter(el => el.clearingAmount).map((item, i) =>
            <>
                <tr>
                    <td scope="col table_header poppins_medium"> <p className="mb-0">{<Moment format="DD-MM-YY">{item.createdAt}</Moment>}</p> </td>
                    <td>{item?.Order_ID}</td>

                    <td scope="col table_header poppins_medium"> <p className="mb-0">{item.Reference_No}</p> </td>

                    <td scope="col table_header poppins_medium"> <p className="mb-0">{item?.book?.Author_Name}</p> </td>
                    <td scope="col table_header poppins_medium"> <p className="mb-0">{
                        item?.currency === 'USD' ? (
                            (item?.bookAmount * item?.dollarRate) * 0.7 + ' PKR'
                        ) : (
                            (item?.clearingAmount * 0.7) + ' PKR'
                        )
                    }</p> </td>
                    <td scope="col table_header poppins_medium"> <p className="mb-0">{
                        item?.currency === 'USD' ? (
                            item?.clearingAmount * item?.dollarRate + ' PKR'
                        ) : (
                            item?.clearingAmount + ' PKR'
                        )
                    }</p> </td>
                </tr>


            </>





        )
    }

    render() {

        const { isLoading } = this.state;

        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }

        return (
            <div className="GeneratedReportContainer">
                <div className="text-right mt-4 mb-4">
                    <button className="hide-on-print" onClick={this.printReceipt}>Print</button>
                </div>
                <div className="col-12 ">
                    <div className="row">
                        <div className="col-2">
                            <img className="W_90" src={Logo} />
                            <div className="HorizontalLine"></div>

                        </div>

                        <div className="col-8">
                            <p className="poppins_regular mb-0 SaleReportHEading">Sales Report</p>
                            <p className="poppins_medium  mb-0 SaleReportHEading1">{this.state.reportContent[0]?.order_has_books[0]?.book?.publisher?.user?.Full_Name}</p>
                            <p className="poppins_regular mb-0 SaleReportHEading">From <Moment format="D MMMM YYYY">{this.state.data?.startDate}</Moment> till <Moment format="D MMMM YYYY">{this.state.data?.endDate}</Moment></p>

                        </div>
                        {/* <div className="col-2">
                            <p className="poppins_regular mb-0 SaleReportHEading">0 3 /01 /2021</p>

                        </div> */}


                    </div>





                </div>
                <div className="col-12 GenratedReportTable mt-5">
                    <table className="table table-hover thead-primary ">
                        <thead>
                            <tr>
                                <th scope="col table_header poppins_medium">Date </th>

                                <th scope="col table_header poppins_medium">Order ID  </th>
                                <th scope="col table_header poppins_medium">Book Name </th>
                                <th scope="col table_header poppins_medium">Book Price </th>

                                <th scope="col table_header poppins_medium">Payment Method </th>
                                <th scope="col table_header poppins_medium">Author's Earning</th>
                                <th scope="col table_header poppins_medium">TLBC's Earning</th>
                                <th scope="col table_header poppins_medium">
                                    <p className="mb-0">Total Sale</p>
                                </th>


                            </tr>
                        </thead>

                        <tbody>
                            {this.state.reportContent && this.renderTableRows()}
                        </tbody>

                        <tfoot className="mt-5 Foot borderTp">
                            <tr>
                                <th scope="col table_header poppins_medium text-left" colspan="5"><p className="PaymentTransfer" style={{ textAlign: 'left', margin: '0px', fontWeight: '500', fontSize: '22px' }}>Total  </p></th>
                                {/* <th scope="col table_header poppins_medium" >{this.state.totalEarning} </th> */}


                                <th scope="col table_header poppins_medium">
                                    <p className="mb-0  PaymentTransfer" style={{ textAlign: 'left', fontSize: '18px' }}>
                                        {
                                            this.state.totalAuthorEarning + ' PKR'
                                        }
                                    </p>
                                </th>
                                <th scope="col table_header poppins_medium">
                                    <p className="mb-0 PaymentTransfer" style={{ textAlign: 'left', fontSize: '18px' }}>
                                        {
                                            this.state.totalTLBCEarning + ' PKR'
                                        }
                                    </p>
                                </th>

                                <th scope="col table_header poppins_medium">
                                    <p className="mb-0 PaymentTransfer" style={{ textAlign: 'left', fontSize: '18px' }}>
                                        {
                                            this.state.totalSale + ' PKR'
                                        }
                                    </p>
                                </th>

                            </tr>
                        
                        </tfoot>

                    </table>

                </div>

                <div className="col-12 GenratedReportTable2 mt-5">
                    <p className="PaymentTransfer" style={{ textAlign: 'left', margin: '0px', fontWeight: '500', fontSize: '22px' }}>Payment Transfer</p>
                    <table className="table table-hover thead-primary ">
                        <thead>
                            <tr>


                                <th scope="col table_header poppins_medium"><p className="mb-0">Date</p>  </th>
                                <th scope="col table_header poppins_medium"><p className="mb-0">Order ID</p>  </th>

                                <th scope="col table_header poppins_medium"><p className="mb-0">Reference No. </p>  </th>

                                <th scope="col table_header poppins_medium"><p className="mb-0">Author Title</p> </th>
                                <th scope="col table_header poppins_medium"><p className="mb-0">Author Earning</p> </th>
                                <th scope="col table_header poppins_medium"><p className="mb-0">Amount</p>  </th>




                            </tr>
                        </thead>

                        <tbody>




                            {this.renderPayement()}

                            <tr>
                                <td scope="col table_header poppins_medium text-left" colspan="4"><p className="GenratedReportTotalEaring">Total </p></td>
                                <td>{this.state.totalAuthorEarning}</td>

                            </tr>
                            <tr>
                                <td scope="col table_header poppins_medium text-left" colspan="4"><p className="GenratedReportTotalEaring">Balance </p></td>
                                <td>{this.state.totalAuthorBalance} </td>
                            </tr>

                            <tr>
                                <td scope="col table_header poppins_medium text-left" colspan="4"><p className="GenratedReportTotalEaring">Total Given </p></td>
                                <td>{ this.state.totalAuthorEarning - this.state.totalAuthorBalance} </td>
                            </tr>



                        </tbody>


                    </table>

                </div>

                {/* {this.renderPayement()} */}

                <div className="mt-5 col-12 text-center">
                    <p className="PageNAv">Page 1 /1</p>
                    <div className="HR"></div>
                    <p className="PageNAv">w w w. l i t t l e b o o kc o m pa n y. n e t</p>

                </div>
            </div>

        )
    }

}

GeneratedReport.propTypes = {

};


const mapStateToProps = state => ({
    auth: state.auth,
    user: state.auth.user
});

const mapDispatchToProps = ({

})
export default connect(mapStateToProps, mapDispatchToProps)(GeneratedReport);

import React, { Component } from 'react'
import Lottie from 'react-lottie';
import animationData from '../../assets/Lottie/books-stack-realistic.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    speed: 0.2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

export default class LottieAnimation extends Component {
  render() {
    return (
        <div>
        <Lottie 
          options={defaultOptions}
          height={450}
          width={400}
        />
      </div>
    )
  }
}

import React, { Component } from "react";

import { connect } from "react-redux";

import searchicon from "../../assets/images/Managebooks/searchicon.svg";
import Polygon from "../../assets/images/Managebooks/Polygon.svg";
import tableBook from "../../assets/images/Managebooks/tableBook.svg";
import plus from "../../assets/images/Managebooks/plus.png";
import printIcon from "../../assets/images/Managebooks/print.png";
import DataTable from "../../components/DataTable/DataTable";
import { Dropdown, Modal, Form, DropdownButton } from "react-bootstrap";

import moment from "moment";
import Moment from "react-moment";
import CloseIcon from "../../assets/images/purchasehistory/PrintModal/close.png";
import PlaceHolder from "../../assets/images/purchasehistory/PlaceHolder.png";

import {
  getAllOrders,
  sortOrderByBook,
  sortOrderByOrder,
  searchBook,
  getTotalEarning,
  getTotalPending,
  createPaymentOfOrder,
} from "../../store/actions/orderAction";
import LottieAnimation from "../../components/LottieAnimation/LottieAnimation";

var cx = require("classnames");

class SellingHistoryAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: [],
      errors: [],
      serverError: {},
      isLoading: false,
      sortByName: false,
      sortByDate: false,
      sortByStatus: false,
      sortByReference: false,
      sortByPrice: false,
      search: "",
      totalearning: "",
      pendingTotal: "",
      dayEarning: "",
      dayPending: "",
      PrintModal: false,
      ReferenceModal: false,
      author: {},
      Reference_No: "",
      Status: "",
      Amount: "",
      index: {},
      currentPage: 1,
      todosPerPage: 15,
      isUploading: false,
      OrderListFiltered: [],
      fromDate: "2021-05-01T01:10:00Z",
      toDate: "2021-10-01T01:10:00Z",
      earning: 0,
      clearedBills: 0,
      isLoading: true,
      clearingAmountLabel: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) { }

  componentDidMount() {
    this.props
      .getAllOrders("1")
      .then((res) => {
        console.log('Response', res.content);
        if (res.status) {
          this.setState(
            {
              orderList: res.content,
            },
            () => {
              this.getTotalEarning(res.content);
              this.setState({
                ["SORT" + "createdAt"]: "DESC",
                isLoading: false,
              });
              let { orderList, OrderListFiltered } = this.state;
              let orderListSorted = orderList.sort(
                this.sortArrByOrder("createdAt", "DESC")
              );
              let OrderListFilteredSorted = OrderListFiltered.sort(
                this.sortArrByOrder("createdAt", "DESC")
              );
              this.setState({
                orderList: orderListSorted,
                OrderListFiltered: OrderListFilteredSorted,
              });
            }
          );
        } else {
          alert(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // this.props.getTotalEarning(300).then((res) => {
    //     console.log("Total Earnings", res.content)
    //     if (res.status) {
    //         this.setState({
    //             totalearning: res.content[0]?.order_book?.total_amount,
    //         })

    //     }
    //     else {
    //         alert(res)
    //     }
    // }).catch((err) => {
    //     console.log(err)

    // })
  }

  onPayment = (item) => {
    console.log('Pressed')
    var addBookData = {
      Order_ID: item?.Order_ID,
      Book_ID: item?.Book_ID,
      Status: this.state.Status,
      Reference_No: this.state.Reference_No,
      clearingAmount: this.state.Amount,
    };
    // this.setState({ isLoading: true })
    // var msg = "Succsessfully Add item";
    // if (this.state.item_id != null) {
    //   msg = "Succsessfully Update item";
    // }
    this.setState({ isUploading: true });

    this.props
      .createPaymentOfOrder(addBookData)
      .then((res) => {
        console.log('Response of Create Payment', res.status);
        if (res.status) {
          console.log(res);

          // this.setState({
          //     Book_ID: res.content[0] && res.content[0].Book && res.content[0].Book.Book_ID,
          //     activeTab: this.state.activeTab + 1,
          // })
          //this.props.history.push('/trackmyrecord');

          this.props
            .getAllOrders("1")
            .then((res) => {
              if (res.status) {
                this.setState({
                  orderList: res.content,
                  ReferenceModal: false,
                  isUploading: false,
                }, () => {
                  console.log('Updated LiSt', this.state.orderList);
                  this.getTotalEarning(res.content);
                  this.setState({
                    ["SORT" + "createdAt"]: "DESC",
                    isLoading: false,
                  });
                  let { orderList, OrderListFiltered } = this.state;
                  let orderListSorted = orderList.sort(
                    this.sortArrByOrder("createdAt", "DESC")
                  );
                  let OrderListFilteredSorted = OrderListFiltered.sort(
                    this.sortArrByOrder("createdAt", "DESC")
                  );
                  this.setState({
                    orderList: orderListSorted,
                    OrderListFiltered: OrderListFilteredSorted,
                  });
                });
                // window.location.reload()
              } else {
                alert(res);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        var validationError = [];
        var serverError = [];
        this.setState({ isUploading: false });

        console.log(err.hasOwnProperty("validation"));
        if (err.hasOwnProperty("validation")) {
          err.validation.map((obj) => {
            if (obj.hasOwnProperty("param")) {
              validationError[obj["param"]] = obj["msg"];
            } else {
              serverError = [...serverError, obj];
            }
          });
          this.setState({ errors: validationError });
          this.setState({ serverError: serverError });
        } else {
          this.setState({ serverError: [{ msg: "server not responding" }] });
        }
      });
  };

  onPressSortByBook = (colName, sort) => {
    this.props
      .sortOrderByBook(colName, sort)
      .then((res) => {
        console.log(res.content);
        if (res.status == true) {
          if (colName == "Name") {
            this.setState({
              orderList: res.content,
              sortByName: !this.state.sortByName,
            });
          } else if (colName == "Price") {
            this.setState({
              orderList: res.content,
              sortByPrice: !this.state.sortByPrice,
            });
          }
        } else {
          alert(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onPressSortByOrder = (colName, sort) => {
    this.props
      .sortOrderByOrder(colName, sort)
      .then((res) => {
        console.log(res.content);
        if (res.status == true) {
          if (colName == "createdAt") {
            this.setState({
              orderList: res.content,
              sortByDate: !this.state.sortByDate,
            });
          } else if (colName == "Reference_No") {
            this.setState({
              orderList: res.content,
              sortByReference: !this.state.sortByReference,
            });
          } else if (colName == "Payment_Status") {
            this.setState({
              orderList: res.content,
              sortByStatus: !this.state.sortByStatus,
            });
          }
        } else {
          alert(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSelectChange = (e, type) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (type == "Earning") {
        this.props
          .getTotalEarning(this.state.dayEarning)
          .then((res) => {
            console.log(res.content);
            if (res.status) {
              this.setState({
                totalearning: res.content[0]?.book?.total_amount,
              });
            } else {
              alert(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type == "Pending") {
        this.props
          .getTotalPending(this.state.dayPending)
          .then((res) => {
            console.log(res.content);
            if (res.status) {
              this.setState({
                pendingTotal: res.content[0]?.book?.total_amount,
              });
            } else {
              alert(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  onChangeText = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.onSearch(e.target.value);
    });
  };

  onClickFailed = () => {
    let { orderList } = this.state;
    let fiteredList = orderList.filter((item) => item?.order_book?.Payment_Status === "Failed")
    this.setState({
      currentPage: 1,
    });
    //console.log(fiteredList)
    this.setState({ OrderListFiltered: fiteredList });
  }
  onSearch = async (searchStr) => {
    if (!searchStr) {
      this.setState({ OrderListFiltered: [] });
      return;
    }
    let { orderList } = this.state;
    //console.log(orderList)
    let fiteredList = orderList.filter((item) => {
      if (
        item?.book?.Name.toLowerCase().includes(searchStr.toLowerCase()) ||
        item?.order_book?.Payment_Method.toLowerCase().includes(
          searchStr.toLowerCase()
        ) ||
        item?.Order_ID.toLowerCase().includes(searchStr.toLowerCase())
      )
        return true;
      return false;
    });
    this.setState({
      currentPage: 1,
    });
    //console.log(fiteredList)
    this.setState({ OrderListFiltered: fiteredList });
  };
  onClickActions = (item) => {
    console.log(item)
    this.setState({
      ReferenceModal: true,
      index: item,
      Amount: item.order_book.currency == 'PKR' ? item.bookAmount * 0.7 : (item.bookAmount * item.order_book.dollarRate) * 0.7,
      clearingAmountLabel: item.order_book.currency == 'PKR' ? '' : `${item.bookAmount}$ - 30% TLBC Commision = ${item.clearingAmount}$ (${item.clearingAmount} * ${item.order_book.dollarRate} CR)`
    }, () => console.log('Clicked Item', item))
  }
  onClickSearch = () => {
    this.props
      .searchBook(this.state.search)
      .then((res) => {
        console.log(res.content);
        if (res.status == true) {
          this.setState({
            orderList: res.content,
          });
        } else {
          alert(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleClick = (type) => {
    if (type === "next") {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    } else if (type === "previous") {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  getTotalEarning = (list) => {
    console.log('LIST', list)
    if (list.length > 0) {
      var earning = 0;
      var clearedBills = 0;

      for (var i = 0; i < list.length; i++) {

        if (list[i].Status == 'Cleared' && list[i].Reference_No !== null) {
          if(list[i].order_book.currency == 'PKR'){
            clearedBills = clearedBills + parseInt(list[i].clearingAmount);
          }
          clearedBills = clearedBills + parseInt(list[i].clearingAmount * list[i].order_book.dollarRate);
        }

        if(list[i].order_book.currency == 'PKR'){
          earning = earning + parseInt(list[i].bookAmount);
        }else{
          earning = earning + parseInt(list[i].bookAmount * list[i].order_book?.dollarRate);
        }

      }

      console.log('My Earning : ', earning)
      console.log('Cleared Bills : ', clearedBills)

      this.setState({ earning: earning, clearedBills: clearedBills });
    }
  };

  renderTableRows = (list) => {
    if (list?.length < 1) {
      return (
        <tr>
          <td className="text-center">
            <b className="table-text"> No Result</b>
          </td>
        </tr>
      );
    }

    return list.map((item, i) => (
      <tr>
        <td>
          <img className="sellingHistoryImg" src={item?.book?.Image}></img>
        </td>
        <td>{item?.book?.Name}</td>
        <td>{item?.Order_ID}</td>

        <td>
          <Moment format="DD-MM-YY HH:mm">
            {new Date(item?.order_book?.createdAt.slice(0, -1))}
          </Moment>
        </td>

        <td>
          {item?.order_book?.Payment_Method === "PayPal"
            ? item?.book?.Price_USD * 150 + " Rs."
            : item?.book?.Price + " Rs."}
        </td>
        <td>
          {item?.order_book?.Payment_Method === "PayPal"
            ? item?.book?.Price_USD * 150 * 0.7 + " Rs."
            : item?.book?.Price * 0.7 + " Rs."}
        </td>
        <td>{item?.order_book?.Payment_Method}</td>

        <td>{item?.order_book?.Reference_No}</td>
        <td>{item?.order_book?.user.Email}</td>

        <td>
          <div
            className={
              item?.order_book.Payment_Status.Status === "Cleared"
                ? "tableSelect_Published"
                : "table-badge-review"
            }
          >
            <label className="badge-label">
              {!item?.order_book.Payment_Status
                ? "Pending"
                : item?.order_book.Payment_Status === "Published"
                  ? "Sold"
                  : item?.order_book.Payment_Status}
            </label>
          </div>
        </td>
        {item?.Reference_No ? (
          <td>{item?.Reference_No}</td>
        ) : (
          <td>
            <img
              className="imgHover"
              src={plus}
              onClick={() =>
                this.setState({ index: item, ReferenceModal: true }, () =>
                  console.log(this.state.index)
                )
              }
            />
          </td>
        )}
      </tr>
    ));
  };

  togglecongratulationModal = () => {
    this.setState({ CongratulationModal: !this.state.CongratulationModal });
  };

  handlecongratulationClosemodal = (e) => {
    this.setState({ CongratulationModal: !this.state.CongratulationModal });
  };
  handleChange(event) {
    if (event.target.value == 1) {
      this.setState({ CongratulationModal: !this.state.CongratulationModal });
    }
  }
  onClickBottomBar = (val) => {
    this.setState({
      activeTab: val,
    });
  };

  onSort = (name, order) => {
    console.log("ORDER : " + order);

    this.setState({ ["SORT" + name]: order });
    let { orderList, OrderListFiltered } = this.state;
    let orderListSorted = orderList.sort(this.sortArrByOrder(name, order));
    let OrderListFilteredSorted = OrderListFiltered.sort(
      this.sortArrByOrder(name, order)
    );
    this.setState({
      orderList: orderListSorted,
      OrderListFiltered: OrderListFilteredSorted,
    });
  };

  getValue = (item, prop) => {
    switch (prop) {
      case "createdAt":
        return item.order_book.createdAt;
      default:
        return item[prop];
    }
  };

  sortArrByOrder = (prop, order) => {
    if (order === "ASC")
      return (a, b) => {
        if (this.getValue(a, prop) > this.getValue(b, prop)) {
          return 1;
        } else if (this.getValue(a, prop) < this.getValue(b, prop)) {
          return -1;
        }
        return 0;
      };

    return (a, b) => {
      if (this.getValue(a, prop) < this.getValue(b, prop)) {
        return 1;
      } else if (this.getValue(a, prop) > this.getValue(b, prop)) {
        return -1;
      }
      return 0;
    };
  };

  getBetweenDate = () => {
    const { orderList, OrderListFiltered, fromDate, toDate } = this.state;
    let start = new Date(fromDate).getTime();
    let end = new Date(toDate).getTime();
    console.log(orderList);

    var orderListSorted = orderList.filter((d) => {
      var time = new Date(d.createdAt).getTime();
      return start < time && time < end;
    });
    var OrderListFilteredSorted = OrderListFiltered.filter((d) => {
      var time = new Date(d.createdAt).getTime();
      return start < time && time < end;
    });
    console.log("betweenList", orderListSorted);
    this.setState({
      orderList: orderListSorted,
      OrderListFiltered: OrderListFilteredSorted,
    });
  };

  render() {
    const {
      isLoading,
      orderList,
      currentPage,
      todosPerPage,
      OrderListFiltered,
    } = this.state;
    const { errors } = this.state;

    if (isLoading) {
      return (
        //<div className="loader-large"></div>
        <LottieAnimation />
      );
    }
    let printList = this.state.search ? OrderListFiltered : orderList;

    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = printList.slice(indexOfFirstTodo, indexOfLastTodo);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(printList.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <Modal
            dialogClassName="col-xl-12 "
            show={this.state.PrintModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="  modal-body">
              <div className="container-fluid ModalContainer">
                <div className="col-12">
                  <div className="row">
                    <div className="col-10">
                      <p className="poppins_medium ModalHading">
                        Print Sell Report
                      </p>
                    </div>
                    <div className="col-2 text-right">
                      <img
                        className="Hov"
                        onClick={() => this.setState({ PrintModal: false })}
                        src={CloseIcon}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <p className="poppins_medium Modaltext">Start Date </p>
                      <input
                        type="date"
                        name="fromDate"
                        onChange={this.onChange}
                        value={this.state.fromDate}
                      />
                    </div>
                    <div className="col-6 ">
                      <p className="poppins_medium Modaltext">End Date </p>
                      <input
                        type="date"
                        name="toDate"
                        onChange={this.onChange}
                        value={this.state.toDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 text-right mt-5 pt-5">
                  <button
                    className="PrintModalBtn"
                    onClick={() => this.getBetweenDate()}
                  >
                    Print Report
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            dialogClassName="col-xl-12 "
            show={this.state.ReferenceModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="  modal-body">
              <div className="container-fluid ModalContainer">
                <div className="col-12">
                  <div className="row">
                    <div className="col-10">
                      <p className="poppins_medium ModalHading">
                        Reference ID: {this.state.index?.ID}
                      </p>
                    </div>
                    <div className="col-2 text-right">
                      <img
                        className="Hov"
                        onClick={() => this.setState({ ReferenceModal: false })}
                        src={CloseIcon}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  {this.state.isUploading && (
                    <div className="loader-small"></div>
                  )}

                  <div className="row">
                    <div className="col-8">
                      <div className="col-12 mt-5">
                        <div className="row">
                          <div className="col-5 Vertical_center text-right">
                            <p className="poppins_medium Modaltext mb-0">
                              Author Name
                            </p>
                          </div>
                          <div className="col-7 ">
                            <input
                              className="ModalInput col-12 "
                              value={this.state.index?.book?.Author_Name}
                              disabled={true}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <div className="row">
                          <div className="col-5 Vertical_center text-right">
                            <p className="poppins_medium Modaltext mb-0">
                              Book Name
                            </p>
                          </div>
                          <div className="col-7 ">
                            <input
                              className="ModalInput col-12 "
                              value={this.state.index?.book?.Name}
                              disabled={true}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <div className="row">
                          <div className="col-5 Vertical_center text-right">
                            <p className="poppins_medium Modaltext">
                              Clearing Amount
                            </p>
                          </div>
                          <div className="col-7 ">
                            <input
                              className="ModalInput col-12 "
                              name="Amount"
                              onChange={this.onChangeText}
                              value={this.state.Amount}
                            ></input>
                            <label
                              style={{
                                fontSize: '10px',
                                marginTop: '1px'
                              }}
                            >
                              {this.state.clearingAmountLabel}
                            </label>
                          </div>
                        </div>
                        {/* <div className="col-12 mt-1">
                          <div className="row">
                            <label
                              style={{
                                fontSize : '10px',
                                margin : '0 auto'
                              }}
                            >
                            {this.state.clearingAmountLabel}
                            </label>
                          </div>
                        </div> */}
                        {errors.Amount && (
                          <div className="invaliderrorAddNewBookImageModal">
                            {errors.Amount}
                          </div>
                        )}
                      </div>
                      <div className="col-12 mt-3">
                        <div className="row">
                          <div className="col-5 Vertical_center text-right">
                            <p className="poppins_medium Modaltext mb-0">
                              Payment Status
                            </p>
                          </div>
                          <div className="col-7 ">
                            <select
                              className="ModalInput col-12 "
                              name="Status"
                              onChange={this.onChangeText}
                              value={this.state.Status}
                            >
                              <option>Select ...</option>
                              <option value="Cleared">Cleared</option>
                              <option value="Pending">Pending</option>
                              <option value="Failed">Failed</option>
                            </select>

                            {errors.Status && (
                              <div className="invaliderrorAddNewBookImageModal">
                                {errors.Status}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <div className="row">
                          <div className="col-5 Vertical_center text-right">
                            <p className="poppins_medium Modaltext mb-0">
                              Reference Number
                            </p>
                          </div>
                          <div className="col-7 ">
                            <input
                              className="ModalInput col-12 "
                              name="Reference_No"
                              onChange={this.onChangeText}
                              value={this.state.Reference_No}
                            />

                            {errors.Reference_No && (
                              <div className="invaliderrorAddNewBookImageModal">
                                {errors.Reference_No}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4  Vertical_center ">
                      <div className="col-12 mt-3">
                        <p className="poppins_medium Modaltext mb-0">
                          Any Attachment{" "}
                        </p>
                        <img
                          className="mt-3 placeholderImg"
                          src={PlaceHolder}
                        />
                      </div>
                      <div className="col-12 mt-3 text-center">
                        <button
                          className="mdlBtn col-12 poppins_semibold"
                          onClick={() => this.onPayment(this.state.index)}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 managebookContainer">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                <p className="poppins_semibold managebookheading">
                  Purchase History / {this.state.author?.Full_Name}
                </p>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0  mt-4">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4  ">
                    <div className="SellingHistoryTopBarCard">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                        <p className="EaringRs">
                          {this.state.earning == 0
                            ? "0.00"
                            : parseInt(this.state.earning * 0.7) + " RS"}
                        </p>
                        <p className="totalEaring">Total Merchant's Sale</p>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                        <div className="row ">
                          {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5 ">
                                                        <p className="viewtext">View all</p>
                                                    </div>

                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-7 " >
                                                        <select className="select_days" name="dayEarning" onChange={(e) => this.onSelectChange(e, 'Earning')}>
                                                            <option value='7'>7days</option>
                                                            <option value='14'>14days</option>
                                                            <option value='30'>30days</option>

                                                        </select>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4   ">
                    <div className="SellingHistoryTopBarCard">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                        <p className="PendingRs">
                          {
                            this.state.clearedBills == 0 ?
                              "0.00 RS" :
                              parseInt(this.state.clearedBills) + " RS"
                          }
                        </p>
                        <p className="totalEaring">Cleared Bills</p>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                        {/* <div className="row ">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5 ">
                                                        <p className="viewtext">View all</p>
                                                    </div>

                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-7 ">
                                                        <select className="select_days" name="dayPending" onChange={(e) => this.onSelectChange(e, 'Pending')}>
                                                            <option value='7'>7days</option>
                                                            <option value='14'>14days</option>
                                                            <option value='30'>30days</option>

                                                        </select>
                                                    </div>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4  ">
                    <div className="SellingHistoryTopBarCard">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                        <p className="PendingRs1">
                          {
                            this.state.earning == 0 && this.state.clearedBills == 0 ?
                              '0.00' :
                              parseInt((this.state.earning * 0.7) - (this.state.clearedBills)) + " RS"
                          }
                        </p>
                        <p className="totalEaring">Pending Balances </p>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                        {/* <div className="row ">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5 ">
                                                        <p className="viewtext">View all</p>
                                                    </div>

                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-7 ">
                                                        <select className="select_days" name="dayPending" onChange={(e) => this.onSelectChange(e, 'Pending')}>
                                                            <option value='7'>7days</option>
                                                            <option value='14'>14days</option>
                                                            <option value='30'>30days</option>

                                                        </select>
                                                    </div>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mb-3 managebookContainer">
          <DataTable
            orderList={this.state.search ? OrderListFiltered : orderList}
            onChange={this.onChange}
            onSearch={this.onSearch}
            onClickFailed={this.onClickFailed}
            search={this.state.search}
            onClickActions={this.onClickActions}
          />
        </div>
      </div>
    );
  }
}

SellingHistoryAll.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getAllOrders,
  sortOrderByBook,
  sortOrderByOrder,
  searchBook,
  getTotalEarning,
  getTotalPending,
  createPaymentOfOrder,
};
export default connect(mapStateToProps, mapDispatchToProps)(SellingHistoryAll);

import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import SearchField from "../SearchField/SearchField";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Component } from "react";
import Moment from "react-moment";
import { Grid, TableFooter } from "@mui/material";
var cx = require("classnames");
function createData(
  orderID,
  bookDetails,
  date,
  amount,
  // buyerID,
  status,
  image,
  author,
  stripeID,
  tlbcEarns,
  authorEarns,
  bookAmount,
  clearingAmount,
  currency,
  dollarRate
) {
  return {
    orderID,
    bookDetails,
    date,
    amount,
    // buyerID,
    status,
    image,
    author,
    stripeID,
    tlbcEarns,
    authorEarns,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
    bookAmount,
    clearingAmount,
    currency,
    dollarRate
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  console.log('New Row', row)
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid>
            <img className="sellingHistoryImg" src={row.image}></img>
          </Grid>
        </TableCell>
        <TableCell>{row.bookDetails}</TableCell>
        <TableCell>{row.orderID}</TableCell>
        <TableCell>
          <Moment format="DD-MM-YYYY HH:mm">{row.date}</Moment>
        </TableCell>
        <TableCell>{
          row.currency == 'PKR' ? (
            row.bookAmount + ' PKR'
          ) : (
            <span>
              {
                row.bookAmount * row.dollarRate + ' PKR '
              }
              <span style={{ color: '#adb5bd' }}>
                {
                  `(${`$` + row.bookAmount} * ${row.dollarRate + ' CR'})`
                }
              </span>
            </span>
          )
        }</TableCell>
        <TableCell>{row.authorEarns}</TableCell>
        <TableCell>
          <Box
            sx={
              {
                bgcolor: row.status === "Cleared"
                  ? "#0E7542" : row.status === "Failed" ? "#751a0e" : "#517A95",
                //padding-left: 18px;
                textAlign: "center",
                //padding-right: 5px;
                borderRadius: "3px",
                color: "white",
                fontSize: "10px",
                fontFamily: "Montserrat",
                pt: 1
              }
            }
          >
            <label className="badge-label">{row.status}</label>
          </Box>
        </TableCell>
        <TableCell>
          <MoreHorizIcon />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableCell>

                <TableCell><Grid /></TableCell>
              </TableCell>
              <TableCell>
                <Grid container direction="column">
                  <Typography sx={{ fontWeight: "bold" }}>
                    {row.bookDetails}
                  </Typography>
                  <Typography variant="caption">{`By ${row.author}`}</Typography>
                  <Grid>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      Date:{" "}
                    </Typography>
                    <Typography variant="caption">
                      <Moment format="DD-MM-YYYY HH:mm">{row.date}</Moment>
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
              {/* <TableCell>
                <Grid container direction="column">
                  <Grid>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      TLBC ORDER ID:{" "}
                    </Typography>
                    <Typography variant="caption">{row.stripeID}</Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      TX ID STRIPE:{" "}
                    </Typography>
                    <Typography variant="caption">{row.stripeID}</Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      CLEARING NO:{" "}
                    </Typography>
                    <Typography variant="caption">{row.stripeID}</Typography>
                  </Grid>
                </Grid>
              </TableCell> */}
              {/* <TableCell>
                <Grid container direction="column">
                  <Grid>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      NAME:{" "}
                    </Typography>
                    <Typography variant="caption">{row.buyerID}</Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      EMAIL:{" "}
                    </Typography>
                    <Typography variant="caption">{row.buyerID}</Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      NUMBER:{" "}
                    </Typography>
                    <Typography variant="caption">{row.buyerID}</Typography>
                  </Grid>
                </Grid>
              </TableCell> */}
              <TableCell>
                <Grid container direction="column">
                  <Grid>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      TOTAL:{" "}
                    </Typography>
                    <Typography variant="caption">
                      {
                        row.currency == 'PKR' ? (
                          row.bookAmount + ' PKR'
                        ) : (
                          <span>
                            {
                              row.bookAmount * row.dollarRate + ' PKR '
                            }
                            <span style={{ color: '#adb5bd' }}>
                              {
                                `(${`$` + row.bookAmount} * ${row.dollarRate + ' CR'})`
                              }
                            </span>
                          </span>
                        )
                      }
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      TLBC EARNS:{" "}
                    </Typography>
                    <Typography variant="caption">
                      {
                        row.currency == 'PKR' ? (
                          row.bookAmount - row.clearingAmount + ' PKR'
                        ) : (
                          parseInt((row.bookAmount * row.dollarRate) - (row.clearingAmount * row.dollarRate)) + ' PKR'
                        )
                      }
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      AUTHOR EARNS:{" "}
                    </Typography>
                    <Typography variant="caption">{row.authorEarns}</Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    orderID: PropTypes.string.isRequired,
    bookDetails: PropTypes.string.isRequired,
    date: PropTypes.any.isRequired,
    amount: PropTypes.string.isRequired,
    buyerID: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    stripeID: PropTypes.string.isRequired,
    authorEarns: PropTypes.string.isRequired,
    tlbcEarns: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

// const rows = [

//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
//   createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
//   createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
//   createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
// ];
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default class DataTableAuthor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: [],
      page: 0,
      rowsPerPage: 10,
      All: true,
      Failed: false,
      Cleared: false,
      Pending: false
    };
  }

  componentDidMount() {
    this.setState({
      orderList: this.props.orderList,
    });
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  onClickFailed = () => {
    this.setState({ All: false })
    this.setState({ Cleared: false })
    this.setState({ Failed: true })
    this.setState({ Pending: false })
    this.props.onClickFailed();
  };
  onClickPending = () => {
    this.setState({ All: false })
    this.setState({ Cleared: false })
    this.setState({ Failed: false })
    this.setState({ Pending: true })
    this.props.onClickPending();
  };
  onClickCleared = () => {
    this.setState({ All: false })
    this.setState({ Cleared: true })
    this.setState({ Failed: false })
    this.setState({ Pending: false })
    this.props.onClickCleared();
  };
  onClickAll = () => {
    this.setState({ All: true })
    this.setState({ Cleared: false })
    this.setState({ Failed: false })
    this.setState({ Pending: false })
    this.props.onClickAll();
  };

  componentDidUpdate = function (prevProps, prevState, snapshot) {
    if (this.props.orderList !== prevProps.orderList) {
      this.setState({
        orderList: this.props.orderList,
      });
    }
  };
  render() {
    const rows = [];
    console.log(this.state.orderList);
    this.state.orderList.map((item) =>
      rows.push(
        createData(
          item?.Order_ID,
          item?.book?.Name,
          new Date(item?.order_book?.createdAt.slice(0, -1)),
          item?.order_book?.Payment_Method === "PayPal"
            ? item?.book?.Price_USD * 150 + " Rs."
            : item?.book?.Price + " Rs.",

          //item?.order_book?.user.Email,
          item?.order_book.Payment_Status,

          item?.book?.Image,
          item?.book?.Author_Name,

          item?.order_book?.Reference_No,
          item?.order_book?.Payment_Method === "PayPal"
            ? item?.book?.Price_USD * 150 * 0.3 + " Rs."
            : item?.book?.Price * 0.3 + " Rs.",
          item?.order_book?.Payment_Method === "PayPal"
            ? item?.book?.Price_USD * 150 * 0.7 + " Rs."
            : item?.book?.Price * 0.7 + " Rs.",
          item?.bookAmount,
          item?.clearingAmount,
          item?.order_book.currency,
          item?.order_book.dollarRate
        )
      )
    );

    return (
      <Box>
        <Paper>
          <Grid
            container
            sx={{
              display: "flex",
              p: 3,
              justifyContent: "space-around",
            }}
          >
            <Typography variant="h4" style={{ color: '#517A95' }}>Order History</Typography>
            <ButtonGroup
              variant="outlined"
              color="info"
              aria-label="outlined button group"
              style={{ flexGrow: 1, justifyContent: 'flex-end', marginRight: '2%' }}
            >
              <Button onClick={this.onClickAll} style={{ backgroundColor: this.state.All ? '#517A95' : '', color: this.state.All ? 'white' : 'black' }}>All</Button>
              <Button onClick={this.onClickFailed} style={{ backgroundColor: this.state.Failed ? '#517A95' : '', color: this.state.Failed ? 'white' : 'black' }}>Failed</Button>
              <Button onClick={this.onClickPending} style={{ backgroundColor: this.state.Pending ? '#517A95' : '', color: this.state.Pending ? 'white' : 'black' }}>Pending</Button>
              <Button onClick={this.onClickCleared} style={{ backgroundColor: this.state.Cleared ? '#517A95' : '', color: this.state.Cleared ? 'white' : 'black' }}>Cleared</Button>
            </ButtonGroup>
            <SearchField
              onChange={this.props.onChange}
              onClickSearch={this.props.onClickSearch}
              onSearch={this.props.onSearch}
              search={this.props.search}
            />
          </Grid>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>BOOK TITLE</TableCell>

                  <TableCell>BOOK NAME</TableCell>
                  <TableCell>ORDER ID</TableCell>
                  <TableCell>DATE & TIME</TableCell>
                  <TableCell>AMOUNT</TableCell>
                  <TableCell>EARNING</TableCell>
                  <TableCell>PAYMENT STATUS</TableCell>
                  <TableCell>REFERENCE NO.</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(this.state.rowsPerPage > 0
                  ? rows.slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                  )
                  : rows
                ).map((row) => (
                  <Row row={row} />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>

                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30, { label: "All", value: -1 }]}
                    sx={{
                      height: '70px',
                      '.MuiTablePagination-select': {
                        paddingTop: '22%'
                      },
                      '.MuiTablePagination-selectIcon': {
                        paddingLeft: '25%',
                        paddingTop: '20%'
                      },
                      "	.MuiTablePagination-input": {
                        fontWeight: "bold",
                        color: "blue"
                      },
                      '.MuiTablePagination-selectLabel': {
                        marginTop: '2%',
                      },
                      '.MuiTablePagination-displayedRows': {
                        marginTop: '2%'
                      }
                    }}
                    count={rows.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  }
}

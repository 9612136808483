import React, { Component } from 'react'
import { Oval } from 'svg-loaders-react'


export default class CircleLoader extends Component {
  render() {
    return (
        <Oval style={{height: 20, width: 20, filter: 'contrast(180%)'}} stroke="#24a0ed " />
    )
  }
}
